
@import '../styles/core.scss';

.team {
    padding:0 1rem 2rem;

    > .info{
        margin-bottom: 2rem;
    }

    .header{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }

    img{
        width: 5rem;
    }

    h1{
        margin:0;
    }

    a{
        color: #ccc;
        text-decoration: none;
        text-align: center;
        display: table;
        margin: 0 auto;

        &:hover{
            color: #777777;
        }
    }

}

.grid.roster-list{
    grid-template-columns: 12fr;
    grid-row-gap: 1.4rem;
    grid-column-gap: 1rem;
}

.player{
    position: relative;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 5rem 5fr 2rem;
    grid-column-gap: 0.6rem;
    border: 1px solid #ccc;

    button {
        margin: -1px;
        padding: .4rem;
        border: 1px solid transparent;
        transform: scale(1);
        transition: transform .2s ease-in-out;
        border: 1px solid #ccc;
        background-color: #f1f1f161;

        svg {
            fill: #333;
            transform: scale(1);
        }

        &:hover {
            transform: scale(1.1);
            background: #ffddee;
            border-color: pink;

            svg {
                fill: hotpink;
                transform: scale(.9);
            }
        }
        &:active {
            transform: scale(.9);
        }
    }

    .img-wrapper {
        height: 5rem;
        margin-top: .2rem;
        background: #f1f1f161;
    }

    img{
        max-width: 100%;
        vertical-align: bottom;
        // opacity: 0;
        // animation: fade-in .4s .8s forwards;
    }

    .info{
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        margin-bottom: 0;
        padding-bottom: .4rem;
    }

    .no{
        font-size: 1.6rem;
        line-height: 1em;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
    }

    .pos{
        display: inline-block;
        font-size: 0.8rem;
    }


    .player-stats{
        grid-column-start: 1;
        grid-column-end: 3;
        padding: 0.2rem 0.6rem;
        font-size: 0.8rem;
        color: #fff;
        background: $font-color;
        

        .no-stats{
            font-size: 0.8rem;
            color: #fff;
            background: $font-color;
        }

        .score-wrapper{
            display: flex;
            flex-flow: wrap row;
            justify-content: space-between;
        }
        .-txt-xl{
            font-weight: 700;
            font-size: 1rem;
        }
        .-txt-l{
            font-weight: 700;
            font-size: 0.8rem;
        }
        .totalScore{
            width: 100%;
            border-bottom: 1px solid #ccc;
            padding-bottom: 0.4rem;
        }
        .playerValue, .valuePerPoint{
            width: 50%;
        }
        .valuePerPoint{
            text-align: right;
            color: lightpink;
        }
    }
}

.cap, .rookie{
    margin-left: 0.2rem;
    padding: 0.2rem;
    font-size: 0.6rem;
    line-height: 1em;
    font-weight: 700;
    text-align: right;
    color: #000;
    background: $bg-color;
}
.cap{
    font-size: 0.8rem;
    padding: 0.4rem;
    border: 1px solid #ccc;
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
}

.rookie{
    position: absolute;
    right: 0;
    bottom: 0;
}

@media only screen and (min-width : 480px)  {
    .grid.roster-list{
        grid-template-columns: 6fr 6fr;
    }
}

@media only screen and (min-width : 768px)  {
    .grid.roster-list{
        grid-template-columns: 4fr 4fr 4fr;
    }
}

@media only screen and (min-width : 1024px)  {
    .grid.roster-list{
        grid-template-columns: 3fr 3fr 3fr 3fr;
    }
}
