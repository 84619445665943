@import './reset.scss';
@import './mixins.scss';
@import './variables.scss';


body {
	font-size: 20px;
	font-family: 'Open Sans Condensed', Arial, sans-serif;
	color: $font-color;

	&.modal-open {
		overflow: hidden;
	}
}

*:focus {
    outline:1px solid pink;
}
h1, h2, h3{
	text-align: center;
	font-weight: 300;
}

button{
	padding: 0.2rem;
	font-size: 0.8rem;
	font-family: 'Open Sans Condensed', Arial, sans-serif;
	color: $font-color;
	border: 1px solid #ccc;

	&:hover{
		cursor: pointer;
		color: $link-color;
	}
}

a:hover{
  	cursor: pointer;
}

table {

	caption {
		font-size: 1.2rem;
	}

	td,
	th {
		font-size: 1rem;
	}
}

.page-header{
    display: flex;
    flex-flow: row wrap;
	justify-content: center;
	align-items: center;

    img{
        max-width: 3rem;
        max-height: 3rem;
    }

    h1{
        margin:0 0 0 1rem;
        line-height: 1.2em;
    }
}

.grid{
	display: grid;
	grid-template-columns:repeat(12, 1fr);
}


@for $i from 1 through 6{
	.col-#{$i}{
		grid-column: span 6;
	}
} 
@for $i from 6 through 12{
	.col-#{$i}{
		grid-column: span 12;
	}
}

section{
  margin: 2rem 0;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.8);
	display: flex;
	justify-content: center;
	align-items: center;

	&__container {
		width: calc(100% - 5rem);
		height: calc(100% - 5rem);
		padding: 3rem;
		background: #fff;
		box-sizing: border-box;
		overflow: scroll;
	}

	&__close {
		position: fixed;
		top: 1rem;
		right: 1rem;
		width: 3rem;
		height: 3rem;
		color: #fff;
		background: #000;
		border-radius: 100%;
		font-size: 2rem;
		line-height: 1em;
		font-family: monospace;

		&:hover {
			border-color: hotpink;
		}
	}

}


@media only screen and (min-width : 768px)  {
	@for $i from 1 through 12{
		.col-#{$i}{
			grid-column: span #{$i};
		}
	} 
}


@keyframes App-logo-spin {
  from {
	transform: rotate(0deg);
  }
  to {
	transform: rotate(360deg);
  }
}
