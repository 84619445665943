
@import '../styles/core.scss';

.team-list{
	display: flex;
	flex-flow: wrap row;
	justify-content: center;
	padding-left: 0.4rem;
	padding-right: 0.4rem;
}
.team-list button{
	margin: 0.2rem;
	padding: 0.2rem 0.4rem;
	flex-grow: 1;
	max-width: 4.2rem;
	transform: scale(1);
	opacity: 0;
	animation: fade-in .4s 0s forwards;

	@for $i from 1 through 31 {
	  &:nth-child(#{$i}) {
			// $delay: $i * 0.06; 
			$delay: random() * 1.5;
			animation-delay: #{$delay}s;
	  }
	}

	&:hover {
		transform: scale(1.1);
	}

	&.-active,
	&.-active:hover {
		transform: scale(1);
	}

	&:hover,
	&.-active{
		color: $link-color;
		background: #ffddee;
		border-color: pink;
	}

	img{
		width: 3rem;
	}
}

@keyframes fade-in{
	0%   {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
