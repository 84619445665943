
@import '../styles/core.scss';

.sort-list{
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    
    button{
        position: relative;
        text-transform: uppercase;
        font-size: 1rem;
        margin: 0 0.2rem;
        border: 0;
        background: none;

        &.-active{
            color: $link-color;
        }
    }
}
